<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-palette-swatch-outline</v-icon>
        Pedidos
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-card flat>
          <v-card-title class="align-end gap">
            <v-text-field
              style="max-width: 400px"
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              clearable
              dense
              hide-details
            ></v-text-field>

            <v-checkbox
              v-model="showAll"
              label="Exibir pedidos concluídos/cancelados"
              class="align-self-end"
              color="xbColor"
              hide-details
            ></v-checkbox>

            <v-spacer />

            <v-btn color="xbColor" @click="novoPedido = true"
              >Criar pedido</v-btn
            >
          </v-card-title>

          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :search="search"
              :items="pedidosFiltered"
              :loading="loading"
              :items-per-page="10"
              :sort-desc="true"
              class="data-tables data-tables__row-click"
              @click:row="onRowClick"
            >
              <!-- ID -->
              <template v-slot:item.id="{ item }">
                {{ item.id }}
              </template>

              <!-- Cliente -->
              <template v-slot:item.cliente="{ item }">
                {{ item.cliente?.nome }}
              </template>

              <!-- Data -->
              <template v-slot:item.data="{ item }">
                <div class="d-flex align-center justify-space-between">
                  {{
                    dateFormat(
                      new Date(item.data).toISOString(),
                      "dd/MM/yyyy 'às' HH:mm"
                    )
                  }}
                </div>
              </template>

              <!-- valor -->
              <template v-slot:item.valor_total="{ item }">
                <div class="d-flex align-center justify-space-between">
                  R$ {{ item.valor_total | currency }}
                </div>
              </template>

              <template v-slot:item.status="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="
                        item.status === 0
                          ? 'orange darken-2'
                          : item.status === 1
                          ? 'green darken-2'
                          : 'yellow darken-1'
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{
                        item.status === 0
                          ? "mdi-alert-circle"
                          : item.status === 1
                          ? "mdi-check-circle"
                          : "mdi-close"
                      }}
                    </v-icon>
                  </template>
                  <span>
                    {{
                      item.status === 0
                        ? "Pendente"
                        : item.status === 1
                        ? "Entregue"
                        : "Cancelado"
                    }}
                  </span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
      <MoreInfoPedido
        :dialog="showDialog"
        :pedido="pedido"
        @closeDialog="showDialog = false"
        @updateTable="getPedidos"
      />
      <NovoPedidoDialog
        :dialog="novoPedido"
        @closeDialog="novoPedido = false"
        @updateTable="getPedidos"
      />
    </v-card>
  </v-container>
</template>

<script>
import { allPedidos } from "@/api/admin/loja.js";
import { dateFormat } from "@/filters";

export default {
  name: "pedidos",

  data() {
    return {
      loading: true,
      loadingDispositivos: false,
      pedidos: [],
      search: "",
      showAll: false,
      showDialog: false,
      novoPedido: false,
      pedido: null,
    };
  },

  components: {
    MoreInfoPedido: () => import("./components/MoreInfoPedido.vue"),
    NovoPedidoDialog: () => import("./components/NovoPedidoDialog.vue"),
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          value: "id",
          width: "80px",
        },
        {
          text: "Data",
          value: "data",
          width: "12rem",
        },
        {
          text: "Assento",
          value: "assento",
          width: "8rem",
        },
        {
          text: "Cliente",
          value: "cliente",
        },
        {
          text: "Valor",
          value: "valor_total",
          width: "8rem",
        },
        {
          text: "Status",
          value: "status",
          width: "80px",
          sortable: false,
          align: "center",
        },
      ];
    },

    pedidosFiltered() {
      let pedidosFiltrados = this.pedidos;
      if (!this.showAll)
        pedidosFiltrados = pedidosFiltrados.filter((item) => item.status === 0);
      return pedidosFiltrados;
    },
  },

  watch: {
    url: {
      handler() {
        this.getPedidos();
      },
      immediate: true,
    },
  },

  methods: {
    getPedidos() {
      this.loading = true;
      allPedidos()
        .then((response) => {
          this.pedidos = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onRowClick(item) {
      this.showDialog = true;
      this.pedido = item;
    },

    dateFormat,
  },
};
</script>

<style scoped>
.gap {
  gap: 1rem;
}
</style>
