import api from "../axios_service.js";

// CATEGORIAS
export async function allCategorias() {
  const response = await api.get(`/produtos-categorias`);
  return response.data.result;
}

export async function updateCategoria(item) {
  const response = await api.put(`/produtos-categorias/${item.id}`, {
    ...item,
  });
  return response.data.result;
}

export async function createCategoria(descricao) {
  const response = await api.post(`/produtos-categorias`, {
    descricao,
    status: 1,
  });
  return response.data.result;
}

export async function getCategoria(id) {
  const response = await api.get(`/produtos-categorias/${id}`);
  return response.data.result;
}

// PRODUTOS
export async function allProdutos() {
  const response = await api.get(`/produtos`);
  return response.data.result;
}

export async function createProduto(request) {
  const response = await api.post(`/produtos`, request, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data.result;
}

export async function updateProduto(item, id) {
  const response = await api.post(`/produtos/${id}`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data.result;
}

export async function getProduto(id) {
  const response = await api.get(`/produtos/${id}`);
  return response.data.result;
}

// Pedidos

export async function allPedidos() {
  const response = await api.get(`/vendas`);
  return response.data.result;
}

export async function updatePedido(item, id) {
  const response = await api.put(`/vendas/${id}`, item);
  return response.data.result;
}

export async function updateItemPedido(item, id) {
  const response = await api.put(`/vendas-itens/${id}`, item);
  return response.data.result;
}

export async function createPedido(item) {
  const response = await api.post("/vendas", item);
  return response.data.result;
}
